<template>
  <div class="container">
    <div class="newForm search">
      <el-form
        ref="searchForm"
        :model="searchForm"
        :rules="searchForm"
        label-width="100px"
      >
        <div class="inlineDiv">
          <!--<div class="queryItem1">-->
          <!--<el-form-item label="打包批次号:" prop="courierNumber">-->
          <!--<el-input v-model="searchForm.courierNumber" clearable placeholder="请输入"></el-input>-->
          <!--</el-form-item>-->
          <!--</div>-->
          <div class="queryItem1">
            <el-form-item label="运单号:" prop="memberId">
              <el-input
                v-model="searchForm.courierNumber"
                clearable
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>
          <el-button type="warning" size="mini" @click="cx">查 询</el-button>
        </div>
      </el-form>
    </div>
    <div class="transfer">
      <el-row>
        <el-col :span="10">
          <div class="leftTable">
            <div class="titleTip">待下架</div>
            <el-table
              :data="leftTable"
              ref="leftTabelref"
              @row-click="rowclick"
              size="mini"
              stripe
              highlight-current-row
              :show-overflow-tooltip="true"
              :max-height="490"
              border
              @selection-change="notJoinSelectionChange"
              style="width: 100%"
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="50"
              >
              </el-table-column>
              <el-table-column type="selection" align="center" width="55">
              </el-table-column>
              <el-table-column
                prop="courierNumber"
                align="center"
                label="快递单号"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="storageName"
                align="center"
                label="仓库"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="nickname"
                label="会员名称"
                align="center"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="memberId"
                align="center"
                min-width="100"
                label="会员号"
              >
              </el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="btnBox">
            <el-button
              type="primary"
              size="small"
              style="margin-bottom: 40px"
              @click="move"
              :disabled="status === 0"
              icon="el-icon-arrow-left"
            >
              向左上架
            </el-button>
            <el-button
              type="success"
              size="small"
              :disabled="status === 1"
              @click="move"
            >
              向右下架 <i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="rightTable">
            <div class="titleTip">已下架</div>
            <el-table
              :data="rightTable"
              ref="rigthTableref"
              @row-click="rowclick2"
              stripe
              border
              :max-height="490"
              size="mini"
              @selection-change="joinSelectionChange"
              style="width: 100%"
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="50"
              >
              </el-table-column>
              <el-table-column type="selection" align="center" width="55">
              </el-table-column>
              <el-table-column
                prop="courierNumber"
                align="center"
                label="快递单号"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="storageName"
                align="center"
                label="仓库"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="nickname"
                label="会员名称"
                align="center"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="memberId"
                align="center"
                min-width="100"
                label="会员号"
              >
              </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import paging from "@/components/pagings.vue";

export default {
  components: { paging },
  data() {
    return {
      searchForm: {}, //  表格搜索数据
      leftTable: [], //  左边表格数据
      rightTable: [], //  右边表格数据
      status: 2,
      selectedData: [], //  表格选中的数据
      nid: "",
    };
  },
  created() {
    this.searchForm.applyPackId = this.$route.query.id;
    this.nid = this.$route.query.id;
    this.myGetDownframeDetail(); //  拣货下架详情
  },
  methods: {
    rowclick(row, event, column) {
      this.$refs.leftTabelref.toggleRowSelection(row);
    },
    rowclick2(row, event, column) {
      this.$refs.rigthTableref.toggleRowSelection(row);
    },
    myGetDownframeDetail() {
      //  拣货下架详情
      Api.getDownframeDetail(this.searchForm).then((res) => {
        if (res.data.status === "success") {
          this.leftTable = res.data.result.noDownframeList || [];
          this.rightTable = res.data.result.downframeList || [];
        } else {
          this.$message.error(`${res.data.message}--待下架列表`);
        }
      });
    },

    cx() {
      //  未入团搜索查询
      this.pageStart = 1;
      this.getNotDownList();
    },
    notJoinSelectionChange(e) {
      //  未入团表格选择
      this.selectedData = e;
      this.status = 0;
    },
    joinSelectionChange(e) {
      //  已入团表格选择
      this.selectedData = e;
      this.status = 1;
    },
    move() {
      //  向左移动
      if (this.selectedData.length > 0) {
        let tip = "上架";
        if (this.status === 0) {
          tip = "下架";
        }
        this.$confirm(
          `即将${tip}选中的${this.selectedData.length}条数据, 是否继续?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          let arr = [];
          this.selectedData.forEach((item) => {
            arr.push(item.packageId);
          });
          let param = {
            downframePackageId: arr,
            applyPackId: this.nid,
            upframePackageId: [],
          };
          if (this.status === 1) {
            param = {
              applyPackId: this.nid,
              upframePackageId: arr,
              downframePackageId: [],
            };
          }
          let sign = tools.getSign(param);
          param.sign = sign;
          Api.downframeConfirm(param).then((res) => {
            if (res.data.status === "success") {
              this.$message.success(res.data.message);
              this.myGetDownframeDetail(); //  拣货下架详情
            } else {
              this.$message.error(`${res.data.message}--下架确认`);
            }
          });
        });
      } else {
        this.$message.warning("当前无数据！");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.team {
  margin-top: 40px;
  padding-bottom: 10px;
  border-bottom: 1px silver solid;
  .queryItem1 {
    /deep/ .el-radio {
      margin-right: 0;
      margin-left: 10px;
    }
  }
}

.search {
  border-bottom: 1px silver solid;
  padding: 20px 0;
  .queryItem1 {
    width: 20%;
  }
}

.container {
  margin: 0 20px;
}

.btnBox {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(200%);
}

.leftTable {
  padding-right: 16px;
  height: 590px;
  border-right: 1px silver solid;
}

.rightTable {
  padding-left: 16px;
  border-left: 1px silver solid;
  height: 590px;
}

.transfer {
  border-bottom: 1px silver solid;
}

.footbut {
  text-align: right;
  margin-top: 10px;
}

.titleTip {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #888888;
}
</style>
